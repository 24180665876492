<template>
  <base-card v-bind="sujet" @menuClick="menuClick" :draggable="draggable">
    <q-avatar slot='avatar' size="3rem" color="comite" text-color="white" icon="icon-icons_sujet" />

    <q-item-label caption>{{$formatDate(sujet.date_limite)}}</q-item-label>
    <q-item-label>
      <router-link :to="{ name: `sujet-show`, params: { id: sujet.id } }">
        <strong>{{sujet.label}}</strong>
      </router-link>
    </q-item-label>
    <div class="q-mt-md">
      <q-item-label v-if="sujet.icra" caption>ICRA: {{ sujet.icra }}</q-item-label>
      <q-item-label v-if="sujet.type" caption>SUJET: {{ sujet.type }}</q-item-label>
    </div>

    <template slot="indicators">
      <q-icon :name="$getIcon('statut', sujet.statut)" />
    </template>

    <q-expansion-item group="evenements" caption dense expand-icon-toggle header-class="q-pa-none">
      <div class="column">
        <div class="col q-py-sm">
          <q-item-label><strong>Description</strong></q-item-label>
          <div :style="{ 'white-space': 'pre-wrap' }">
            <q-item-label>{{sujet.description}}</q-item-label>
          </div>
        </div>
        <div class="col q-py-sm">
          <q-item-label><strong>Compte-rendu</strong></q-item-label>
          <div :style="{ 'white-space': 'pre-wrap' }">
            {{sujet.compte_rendu}}
          </div>
        </div>
      </div>
    </q-expansion-item>
  </base-card>
</template>

<script>
export default {
  name: 'SujetCard',
  props: {
    'sujet': { type: Object, required: true },
    'draggable': { type: Boolean, default: true }
  },

  methods: {
    menuClick ({ name, params }) {
      this.$router.push({ name, params })
    }
  }
}
</script>
