<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="column q-gutter-y-md">
        <q-card flat bordered class="q-pa-sm">
          <q-card-section class="q-gutter-md">
            <base-input-text v-bind="formInputProps('label')" :color="color"
              v-model="formData.label" ref="label" />

            <base-input-text v-bind="formInputProps('description')" :color="color"
              v-model="formData.description" ref="description" />
          </q-card-section>
        </q-card>

        <div v-if="instance && instance.statut !== 'CLOTURE'">
          <div v-if="!isFormOpen" class="row">
            <base-add-button :label="$t('sujets.assign_sujet')" :color="color" @click="isFormOpen = true" />
          </div>

          <div v-else class="row">
            <div class="col col-6">
              <base-input-select :options="selectOptions" @input="onSujetSelect" :color="color" />
            </div>
          </div>
        </div>

        <div class="row q-pt-md">
          <div class="col col-6">
            <q-select outlined dense @input="changeOrder" v-model="activeSujetFilter" :color="color" :options="sujetsFilters" label="Trier par" />
          </div>
        </div>

        <draggable class="row q-mt-none q-pb-md q-col-gutter-md" tag="div" :group="{ name: 'sujet' }" :list="relatedSujets" @end="changeActiveSujetFilter('personnalise')">
          <div class="col col-12" v-for="sujet in relatedSujets" :key="sujet.id">
            <SujetCard :sujet="sujet" draggable />
          </div>
        </draggable>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none" v-if="isAdmin">
          <base-input-select v-bind="formInputProps('region_id')" :color="color"
            v-model="formData.region_id" ref="region_id" />
        </q-item>

        <q-item class="q-pa-none">
          <base-input-date v-bind="formInputProps('date_instance')" :color="color"
            v-model="formData.date_instance" ref="date_instance" />
        </q-item>

        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
        </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" outline @click="$router.go(-1)">{{$t('form.cancel')}}</q-btn>
        <q-btn v-if="$route.params.id" color="negative" class="full-width" size="sm" unelevated icon-right="delete"
          @click.native="destroy">{{$t('instances.delete_instance')}}</q-btn>
        <q-btn v-if="instance && instance.statut === 'PLANIFIE'" :color="color" class="full-width q-pa-sm" size="md" @click="cloturerInstance">{{$t('instances.cloturer_instance')}}</q-btn>
        <q-btn v-if="instance && instance.statut === 'CLOTURE'" :color="color" class="full-width" size="sm" outline @click="downloadPdf">
          <div class="text-center q-py-md">
            <q-icon class="q-ma-none" name="file_copy" />
            <br>{{$t('instances.download_pdf')}}
          </div>
        </q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import download from 'downloadjs'
import { mapGetters } from 'vuex'
import { BaseForm } from '../../mixins'
import { SujetCard } from '../../components/sujets'
import draggable from 'vuedraggable'

export default {
  name: 'InstanceDetails',
  mixins: [BaseForm],
  components: { SujetCard, draggable },
  props: {
    'form_model': { type: String, default: 'instances' },
    'action': { type: String, default: 'instances/saveInstance' }
  },

  data () {
    return {
      isFormOpen: false,
      relatedSujets: [],
      sujetsFilters: [],
      activeSujetFilter: null
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      instance: 'instances/getCurrentInstance',
      sujets: 'sujets/getSujets'
    }),

    label () {
      return !this.$route.params.id ? this.$t('instances.add_instance') : this.$t('instances.edit_instance')
    },

    selectOptions () {
      const related_ids = this.relatedSujets.map(({ id }) => id)
      return this.sujets && this.sujets.filter(sujet => related_ids.indexOf(sujet.id) < 0)
    }
  },

  watch: {
    formFields (newVal, oldVal) {
      this.formData = {
        ...this.formData,
        ...newVal.map(({ id, options }) => ({ id, options }))
          .reduce((acc, { id, options }) => ({
            ...acc, [id]: options ? options.find(({ value }) => value === this.instance[id]) : this.instance[id]
          }), {})
      }

      this.relatedSujets = this.instance['sujets'] || []
    },

    relatedSujets: {
      handler (newVal, oldVal) {
        this.formData['sujet_id'] = newVal && newVal.map(({ id }) => id)
        this.formData.instances_hierarchy = JSON.stringify(newVal.map(instance => { return { id: instance.id } }))
      },
      deep: true
    }
  },
  created () {
    this.sujetsFilters = [
      { label: 'Nature de sujet', value: 'nature' },
      { label: 'ICRA', value: 'icra' },
      { label: 'Tri personnalisé', value: 'personnalise' }
    ]
  },

  mounted () {
    if (this.instance) {
      this.formData.id = this.instance.id
    }
  },

  methods: {
    changeOrder (selectedOrder) {
      const sortBy = selectedOrder.value
      if (sortBy === 'icra') {
        this.relatedSujets = this.relatedSujets.sort((sujet1, sujet2) => {
          if (!sujet1.icra) return 1
          if (!sujet2.icra) return -1
          return sujet1.icra.localeCompare(sujet2.icra)
        })
      }
      if (sortBy === 'nature') {
        this.relatedSujets = this.relatedSujets.sort((sujet1, sujet2) => {
          if (!sujet1.type) return 1
          if (!sujet2.type) return -1
          return sujet1.type.localeCompare(sujet2.type)
        })
      }
    },
    changeActiveSujetFilter (selectedOrder) {
      if (selectedOrder === 'personnalise') {
        this.activeSujetFilter = this.sujetsFilters.find(filter => filter.value === selectedOrder)
      }
    },
    onSujetSelect (sujet) {
      this.relatedSujets.push({ model_type: 'sujet', model_id: sujet.id, ...sujet })
      this.isFormOpen = false
    },

    cloturerInstance () {
      this.$confirm(this.$t('instances.cloturer_instance_confirm'), this.$t('instances.cloturer_instance'), this.color).onOk(() => {
        this.formData.statut = { label: 'CLOTURE', value: 'CLOTURE' }
        this.save()
      })
    },

    downloadPdf () {
      this.$axios.get(`/api/comite/instances/${this.$route.params.id}/export`, {
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'text/pdf' }
      }).then(response => {
        download(response.data, 'Compte-rendu.pdf')
      }).catch(this.$notifyError)
    },

    onSave ({ id }) {
      if (this.$route.params.id) {
        if (this.formData.statut.value === 'CLOTURE') {
          this.$router.go(-1)
        } else {
          this.$store.dispatch('instances/getInstance', this.$route.params.id)
        }
      } else {
        this.$router.go(-1)
      }
    },

    destroy () {
      this.$confirm(this.$t('instances.delete_instance_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('instances/deleteInstance', this.$route.params.id).then(() => {
          this.notifySuccess('instances.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
